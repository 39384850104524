<template>
  <v-card
    style="max-width: 400px; background-color: #efefef"
    class="d-flex flex-column justify-center align-center"
  >
    <div class="forgot__title">Forgot Your Password? Simply Call:</div>
    <div class="forgot__number">
      718-764-0706 <img src="" alt="" class="forgot__number__phone" />
    </div>
    <div class="forgot__text">Our Tech Support Will Reset Your Password.</div>
    <div class="forgot__smalltext">
      Tech Support Is Available From 9 A.M. To 11 P.M.
    </div>
    <div class="forgot__button" @click="redirectToLogin">Log In</div>
    <div class="forgot__button" @click="redirectToSignup">Create Account</div>
    <a class="forgot__redirect" v-show="!cashAvailable" href="https://thekiosko.com">
      &#8592; Back to thekiosko.com</a
    >
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Forgot",
  data: () => ({
    cashAvailable: localStorage.cashAvailable === 'true',
  }),
  methods: {
    ...mapActions(["setIsAuthenticated"]),
    redirectToSignup() {
      this.$router.push({ name: "signup" });
    },
    redirectToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
